<script setup lang="ts">
import { CmsBlock, CmsSlot } from "@shopware-pwa/types";

type CmsBlockMaasStores = CmsBlock & {
  slots: Array<
    CmsSlot & {
      slot: "maasStores";
    }
  >;
};

const props = defineProps<{
  content: CmsBlockMaasStores;
}>();

const { getSlotContent } = useCmsBlock(props.content);
const slot = getSlotContent("stock");
const { getConfigValue } = useCmsElementConfig(slot);

const title = getConfigValue("title");
const subtitle = getConfigValue("subtitle");

const type = getConfigValue("type");

const stores: any = computed(() => {
  return Object.values(slot?.data?.stores).filter((el: any) => el.type !== 'Lagerverkauf') ?? [];
});

const warehouseSales: any = computed(() => {
  return Object.values(slot?.data?.stores).filter((el: any) => el.type === 'Lagerverkauf') ?? []
});
</script>

<template>
  <div class="s-gutter">
    <div :class="type === 'stores' ? 'c-width-default' : 'c-width-narrow'">
      <div v-if="title || subtitle" class="mt-12 mb-12">
        <h1 v-if="title" class="mb-4 text-h1 text-maas-typography-headline-primary">{{ title }}</h1>
        <div v-if="subtitle" class="richtext max-w-5xl" v-html="subtitle"></div>
      </div>

      <div
        v-if="type === 'stores'"
        class="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 3xl:grid-cols-4"
      >
        <div
          v-for="store in stores"
          :key="store.id"
        >
          <NuxtLink :to="`/filialen/standorte/${store.slug}`">
            <SharedCmsImage :src="store?.media[0]?.media?.url" :width="600" :height="600" class-img="aspect-square" />
            <h3 class="mt-4 text-h3 text-maas-typography-headline-primary">{{ store.title }}</h3>
            <p class="text-p">{{ store.subtitle }}</p>
          </NuxtLink>
        </div>
      </div>

      <ul v-if="type === 'warehouseSales'">
        <li
          v-for="warehouseSale in warehouseSales"
          :key="warehouseSale.id"
        >
          <NuxtLink :to="warehouseSale.url" target="_blank" class="bg-maas-background-grey-light hover:bg-maas-background-grey-medium group grid grid-cols-0 md:grid-cols-[2fr_3fr] lg:grid-cols-[2fr_3fr_110px] gap-x-4 gap-y-4 lg:gap-x-8 border-b px-4 lg:px-8 py-6 transition-colors first:border-t">
            <span class="md:col-span-2 lg:col-span-3 text-h4 block w-full font-normal">
              {{ warehouseSale.title }}
              <span v-if="warehouseSale.subtitle" class="block text-h6 font-normal">{{ warehouseSale.subtitle }}</span>
            </span>
            <span class="text-p block">{{ warehouseSale.street }}<br>{{ warehouseSale.zip }} {{ warehouseSale.city }}</span>
            <span class="text-p block" v-html="warehouseSale.businessHours"></span>
            <span class="md:col-span-2 lg:col-span-1 text-maas-typography-headline-primary">
              <span class="inline-flex gap-4 transition duration-500 group-hover:translate-x-1 group-hover:scale-110">
                Anmelden
                <svg class="block " width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 22L12.7692 12L2 2" stroke="#2B5078" stroke-width="2" stroke-linecap="square"></path></svg>
              </span>
            </span>
          </NuxtLink>
        </li>
      </ul>
    </div>
  </div>
</template>